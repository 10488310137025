export default {
    mamespaced : true,
    state: { 
        rsLevel: [], 
        kelolaLevel : []
    },
    mutations: { 
        setRsLevel(state, value) {
            state.rsLevel = value 
        },
        setKelolaLevel(state, value) {
            state.kelolaLevel = value 
        }
    },
    actions: {
   },
   
}
