export default[ 
    
    {
      path: '/master_kat_jabatan',
      name: '/master_kat_jabatan',
      component: () => import('@/views/master/kategori_jabatan/index.vue'),
      meta: {
        pageTitle: 'Master',
        breadcrumb: [ 
          {
            text: 'Master',
            active: false,
          },
          {
            text: 'Kategori Jabatan',
            active: true,
          },
          
        ],
      },
    } ,
    {
        path: '/master_kat_bidang',
        name: '/master_kat_bidang',
        component: () => import('@/views/master/kategori_bidang/index.vue'),
        meta: {
          pageTitle: 'Master',
          breadcrumb: [ 
            {
              text: 'Master',
              active: false,
            },
            {
              text: 'Kategori Bidang',
              active: true,
            },
            
          ],
        },
      } 
]