export default[
    
    {
      path: '/profile',
      name: '/profile',
      component: () => import('@/views/profile/AccountSetting.vue'),
      meta: {
        pageTitle: 'Profile',
        breadcrumb: [ 
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },  
]