export default {
    mamespaced : true,
    state: { 
        Rsuser: [], 
    },
    mutations: { 
        SetRsUser(state, value) {
            state.Rsuser = value 
        }
    },
    actions: {
   },
   
}
