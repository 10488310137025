export default[
    
      {
        path: '/acl_menu',
        name: '/acl_menu',
        component: () => import('@/views/acl/menu/Index.vue'),
        meta: {
          pageTitle: 'ACL sMenu',
          breadcrumb: [ 
            {
              text: 'ACL Menu',
              active: true,
            },
          ],
        },
      },
      {
        path: '/acl_level',
        name: '/acl_level',
        component: () => import('@/views/acl/level/Index.vue'),
        meta: {
          pageTitle: 'ACL Level',
          breadcrumb: [
            {
              text: 'ACL Level',
              active: true,
            },
          ],
        },
      },
      {
        path: '/acl_user',
        name: '/acl_user',
        component: () => import('@/views/acl/user/Index.vue'),
        meta: {
          pageTitle: 'ACL User',
          breadcrumb: [
            {
              text: 'ACL User',
              active: true,
            },
          ],
        },
      },{
        path: '/acl_admin_bidang',
        name: '/acl_admin_bidang',
        component: () => import('@/views/acl/admin_bidang/admin.vue'),
        meta: {
          pageTitle: 'ACL User',
          breadcrumb: [
            {
              text: 'ACL User',
              active: '',
            },
            {
              text: 'Manajemen Admin Bidang',
              active: true,
            },
          ],
        },
      },
      {
        path: '/',
        name: 'login',
        component: () => import('@/views/autorisasi/Login.vue'),
        meta: {
          layout: 'full',
        },
      },  

      
]