export default [

  {
    path: '/kelompok',
    name: '/kelompok',
    component: () => import('@/views/kelompok/index.vue'),
    meta: {
      pageTitle: 'Kelompok',
      breadcrumb: [{
          text: 'Kelompok',
          to: '/kelompok_list'
        },


      ],
    },
    children: [{
        path: '/kelompok_list',
        name: '/kelompok_list',
        component: () => import('@/views/kelompok/list/index.vue'),
        meta: {
          pageTitle: 'Kelompok',
          navActiveLink: '/kelompok_list',
          breadcrumb: [{
              text: 'Kelompok',
              to: '/kelompok_list'
            },
            {
              text: 'Daftar',
              active: true
            },


          ],
        },
      },
      {
        path: '/kelompok_add',
        name: '/kelompok_add',
        component: () => import('@/views/kelompok/add/index.vue'),
        meta: {
          pageTitle: 'Kelompok',
          navActiveLink: '/kelompok_list',
          breadcrumb: [{
              text: 'Kelompok',
              to: '/kelompok_list'
            },
            {
              text: 'Tambah Kelompok',
              active: true
            },


          ],
        },
      },
      {
        path: '/kelompok_kordinat/:id_kelompok',
        name: '/kelompok_kordinat',
        component: () => import('@/views/kelompok/kordinat/index.vue'),
        meta: {
          pageTitle: 'Kelompok',
          navActiveLink: '/kelompok_list',
          breadcrumb: [{
              text: 'Kelompok',
              to: '/kelompok_list'
            },
            {
              text: 'Kelola Titik Koordinat',
              active: true
            },


          ],
        },
      },
      {
        path: '/kelompok_anggota/:id_kelompok',
        name: '/kelompok_anggota',
        component: () => import('@/views/kelompok/anggota/Index.vue'),
        meta: {
          pageTitle: 'Kelompok',
          navActiveLink: '/kelompok_list',
          breadcrumb: [{
              text: 'Kelompok',
              to: '/kelompok_list'
            },
            {
              text: 'Kelola Anggota',
              active: true
            }, 
          ],
        },
      },
    ]
  }, 
  {
    path: '/manajemen_anggota',
    name: '/manajemen_anggota',
    component: () => import('@/views/namajemen_anggota/index.vue'),
    meta: {
      pageTitle: 'Daftar Anggota',
      navActiveLink: '/daftar_anggota',
      breadcrumb: [{
          text: 'Manajemen Anggota',
          to: '/manajemen_anggota'
        },
        {
          text: 'Kelola Anggota',
          active: true
        }, 
      ],
    },
  }, 
]