export default [ 
    {
      path: '/laporan_perkelompok',
      name: '/laporan_perkelompok',
      component: () => import('@/views/laporan/kelompok/list/index.vue'),
      meta: {
        pageTitle: 'Laporan',
        breadcrumb: [{
            text: 'Laporan Perkelompok',
            to: '/kelompok_list'
          },
  
  
        ],
      },
       
    },
    {
      path: '/laporan_perkelompok_list',
      name: '/laporan_perkelompok_list',
      component: () => import('@/views/laporan/kelompok/list/index.vue'),
      meta: {
        pageTitle: 'Laporan',
        navActiveLink: '/laporan_perkelompok_list',
        breadcrumb: [{
            text: 'Laporan Perkelompok',
            to: '/laporan_perkelompok_list'
          }, 
          {
            text: 'Daftar',
            active: true
          },


        ],
      },
    }, 
    {
      path: '/laporan_perkelompok_map/:id_kelompok',
      name: '/laporan_perkelompok_map',
      component: () => import('@/views/laporan/kelompok/map/index.vue'),
      meta: {
        pageTitle: 'Kelompok',
        navActiveLink: '/laporan_perkelompok_list',
        breadcrumb: [{
            text: 'Laporan Perkelompok',
            to: '/laporan_perkelompok_list'
          },
          {
            text: 'Map',
            to: '/laporan_perkelompok_list'
          },
          {
            text: 'Sebaran Presensi Anggota ',
            active: true
          },


        ],
      },
    },
    {
      path: '/laporan_perkelompok_anggota/:id_angota/:date/:user_key',
      name: '/laporan_perkelompok_anggota',
      component: () => import('@/views/laporan/kelompok/anggota/index.vue'),
      meta: {
        pageTitle: 'Kelompok',
        navActiveLink: '/laporan_perkelompok_list',
        breadcrumb: [{
            text: 'Laporan Perkelompok',
            to: '/laporan_perkelompok_list'
          }, 
          {
            text: 'Daftar Anggota',
            active: true
          }, 
        ],
      },
    }, {
      path: '/laporan_perkelompok_detail',
      name: '/laporan_perkelompok_detail',
      component: () => import('@/views/laporan/kelompok/detail_kelompok/index.vue'),
      meta: {
        pageTitle: 'Kelompok',
        navActiveLink: '/laporan_perkelompok_list',
        breadcrumb: [{
            text: 'Laporan Perkelompok',
            to: '/laporan_perkelompok_list'
          }, 
          {
            text: 'Detail Kelompok',
            active: true
          }, 
        ],
      },
    },
    {
        path: '/laporan_anggota',
        name: '/laporan_anggota',
        component: () => import('@/views/laporan/anggota/index.vue'),
        meta: {
          pageTitle: 'Laporan',
          navActiveLink: '/laporan_anggota',
          breadcrumb: [{
              text: 'Laporan Peranggota',
              to: '/laporan_anggota'
            }, 
            {
              text: 'Rekap Bulanan',
              active: true
            },


          ],
        },
      }, 
      {
        path: '/laporan_anggota_detail/:hast1/:hast2/:id_anggota/:date',
        name: '/laporan_anggota_detail',
        component: () => import('@/views/laporan/anggota/detail_anggota/index.vue'),
        meta: {
          pageTitle: 'Laporan',
          navActiveLink: '/laporan_anggota',
          breadcrumb: [{
              text: 'Palaporan peranggota',
              to: '/laporan_anggota'
            }, 
            {
              text: 'Rekap Presensi Peruser',
              active: true
            },


          ],
        },
      }, 
  

      {
        path: '/rekap_presensi',
        name: '/rekap_presensi',
        component: () => import('@/views/presensi/rekap/index.vue'),
        meta: {
          pageTitle: 'Presensi',
          navActiveLink: '/rekap_presensi',
          breadcrumb: [{
              text: 'Presensi',
              to: '/rekap_presensi'
            }, 
            {
              text: 'Rekap',
              active: true
            },


          ],
        },
      },
  ]