export default {
    mamespaced : true,
    state: { 
        IdKelompok : 0,
        RsStatistik: [], 
        RsDate : [],
        RsKelompok : [],
        Tanggal : ""
    },
    mutations: { 
        SetIdKelompok(state, value) {
            state.IdKelompok = value 
        }, 
        SetTanggal(state, value) {
            state.Tanggal = value 
        }, 
        SetRsstatistik(state, value) {
            state.RsStatistik = value 
        }, 
        SetRsDate(state, value) {
            state.RsDate = value 
        }, 
        SetRsKelompok(state, value) {
            state.RsKelompok = value 
        }, 
    },
    actions: {
   },
   
}
