import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import aclUser from './acl/user';
import aclMenu from './acl/menu'; 
import aclLevel from './acl/level';
import laporan from './modul/laporaan'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    aclUser,
    aclMenu,
    aclLevel,
    laporan 

  },
  strict: process.env.DEV,
})
