export default[ 
      {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/error-500',
        name: 'error-500',
        component: () => import('@/views/error/Error500.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/no-akses',
        name: 'no-akses',
        component: () => import('@/views/error/NoAkses.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: '/no-token',
        name: 'no-token',
        component: () => import('@/views/error/NoToken.vue'),
        meta: {
          layout: 'full',
        },
      },
      
      {
        path: '*',
        redirect: 'error-404',
      },
]