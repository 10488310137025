export default {
    mamespaced : true,
    state: { 
        rs_menu: [], 
    },
    mutations: { 
        set_rs_menu(state, value) {
            state.rs_menu = value 
        }
    },
    actions: {
   },
   
}
